import React from 'react';
import {graphql, Link} from 'gatsby';
import styled from 'styled-components'
import Layout from '../components/Layout';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import SEO from '../components/SEO'
import CardLink from '../components/CardLink';
import COLORS from '../utils/colors'
import SIZES from '../utils/sizes'
import '../styles/templates.scss'
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const StyledPortfolioTemplate = styled.div`

    span.categ{
        display:inline-block;
        background-color:${COLORS.primary};
        color:${COLORS.secundary};
        font-family: "Gilroy Light","Catamaran", Arial, Helvetica, sans-serif;
        font-size:0.9rem;
        padding: 4px 16px 5px;
        margin-bottom:12px;
        border-radius:4px;
    }

    .projectHeader{
        position:relative;
        width:100vw;
        margin-left: -50vw;
        left: 50%;
        height:600px;

        margin-bottom:72px;

        .projectInfo
        {
            position:absolute;
            top:50%;
            left:50%;
            transform:translateX(-50%);
            z-index:1;
            display:flex;
            flex-direction:column;
            width:100%;
            
            max-width:${SIZES.firstBreakPoint};
            align-items:center;
            justify-content:center;

            *
            {
                color:${COLORS.primary};
            }


            .info{               

                text-align:center;
                /*background-color:${COLORS.primary};
                */
                padding:16px 0;

                h1{
                    margin-bottom:0;
                    letter-spacing:2px;
                    padding:0 24px;
                }
        
                span.categ{
     
                    color:${COLORS.secundary};
                }
                
            }
            .prev-next-top-slider{
        
                .icon{
                    font-size:3rem;
                  
                }
            }
        }

    
    
        .portfolio-display-image{
            position:absolute !important;
            width:100%;
            height:100%;
        }

        .filtre{
            position:absolute;            
            width:100%;
            
           
        }

        .dark-filter
        {
            height:30%;
            top:0;
            background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0));
        }

        .light-filter
        {
            height:65%;
            bottom:0;
            background: linear-gradient(to top, rgba(255,255,255,0.5), rgba(255,255,255,0));
        }
    }

   .intro
   {
       margin:auto;
       max-width:${SIZES.secondBreakPoint};
       text-align:center;


        ul
        {
            margin: 24px auto 0;
            display:flex;
            max-width:600px;
            justify-content:center;
            flex-wrap:wrap;

            li
            {
                list-style-type:none;
                font-family: "Gilroy Light", "Catamaran", Arial, Helvetica, sans-serif;
                font-size:1rem;
                padding:4px 0;
            }

            li:not(:last-child):after
            {
                content:"/";
                margin:0 16px;
            }
        }
       
   }

    section > article
    {

        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-top:128px;
       


        .visiter-site
        {
           
            margin-bottom:24px;
            text-decoration:none;
            transition: transform .3s ease-out;

            &:hover
            {
                transform: translateX(16px);
            }
        }

        .visiter-site:after
        {
            content:">>>";
            display:inline-block;
            margin-left:8px;

        }

        >div:first-child
        {
            width:calc(100% / 3 - 72px);
          

            h2
            {
                font-family: "Gilroy Light", "Catamaran", Arial, Helvetica, sans-serif;
                text-align:center;
                font-size:1.4rem;
                text-transform:lowercase;
                margin-bottom:48px;
               

                span
                {
                    font-family: "Gilroy ExtraBold", "Catamaran", Arial, Helvetica, sans-serif;
                    font-size:2rem;
          
                   
                }
            }

            p
            {
                margin-bottom:24px;
                text-align:justify;
                font-size:1.2rem;
                line-height:2.2rem;
            }
        }

        .image{
            width:calc(100% / 3 * 2 - 48px);
            img
            {
                border-radius:4px;
            }
            
            .figcaption{
                display:block;
                margin-top:16px;
                font-size:0.8rem;
                line-height:1.2rem;
                text-align:right;
            }
        }

        &:nth-child(2){
            flex-direction:row-reverse;
      

            .image .figcaption
            {
                text-align:left;
            }
        }

        &#showcase
        {
            flex-direction:column;

            a
            {
                display:block;
                text-align:center;
          
            }

        }

    }

    .portfolio-showcase-image{
        max-width:960px;
        margin:auto;

    }

    

    @media (max-width: ${SIZES.firstBreakPoint}){

        .projectHeader{

            h1{
                font-size:2.8rem;
                letter-spacing:1px;
                margin-bottom:8px;
            }

            span{
                font-size:0.8rem;
            }

            .prev-next-top-slider{
                .icon{
                    font-size:2.8rem;
                }
            }
        }

    }

    @media (max-width: ${SIZES.secondBreakPoint}){

        .projectHeader{

            margin-bottom:48px;
            height:400px;

            .projectInfo
            {
                position:absolute;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-15%);
        

                h1{
                    font-size:2rem;
                
                    margin-bottom:0;
                }

                span{
                    font-size:0.8rem;
                }

                .prev-next-top-slider{
                    .icon{
                        font-size:2rem;
                    }
                }
            }
        }

        

        section > article{

            flex-direction:column;
            margin-top:48px;
            &:nth-child(2){
                flex-direction:column;
            }

            >div:first-child
            {
                width:100%;
            }

            .image{
                width:100%;
                margin-top:24px;
            }
        }

    }


    @media (max-width: ${SIZES.fourthBreakPoint}){

        .projectHeader{       

            .prev-next-top-slider{
                .icon{
                    display:none;
                }
            }

            .info{
                padding:8px 0;
                margin: 0 0px;

                
            }

        } 



    section > article {

        >div:first-child{
            p{             
                text-align:center;
            }
        }      
       
    }}
`
export const query = graphql`
    query($slug:String!){
        contentfulPortfolioItem(slug:{eq: $slug}) {
     
            title
            slug
            category
            
            description {
                description
            }

            presentation{
                raw
            }

            displayImage {
                title
                file {
                  url
                }
                gatsbyImageData
               
            }

            step1 {
                title
                description
                file {
                  url
                }
                gatsbyImageData
            }

            step2 {
                title
                description
                file {
                  url
                }
                gatsbyImageData
            }

            showcase {
                title
                file {
                  url
                }
                gatsbyImageData
            }

            
            itemPath
            itemPrestations
        }
    }

   

`



const portfolio = (props) => {
    
    const itemPath = props.data.contentfulPortfolioItem.itemPath
    let lienProjet =''
    itemPath ? lienProjet = <a className="visiter-site" href={itemPath} target="_blank"  rel="noopener noreferrer">Visitez le site</a> : lienProjet = ''

    let categ = props.data.contentfulPortfolioItem.category

    

  const { next, prev } = props.pageContext
  const project = props.data.contentfulPortfolioItem

    return (
        <Layout page="portfolio" theme="template">
            <SEO title={project.title}/>
            <StyledPortfolioTemplate>
                <div className="projectHeader">
                    <div className="projectInfo">
                       
                        <CardLink />    
                
                        <div className="info">
                            <h1 className="single-title">{project.title}</h1>
                            <span className="categ">{categ}</span>
                       
                        </div>

                        <div className="prev-next-top-slider">
                            {next && <Link className="icon next" to={`/portfolio/${next.slug}`}></Link>}
                        
                            { prev && <Link className="icon previous" to={`/portfolio/${prev.slug}`}></Link> }
                        </div>
                    </div>             
                    
                    
                    <GatsbyImage className="portfolio-display-image" image={project.displayImage.gatsbyImageData} alt={project.displayImage.title} objectFit="cover" />
                
                    <div className="filtre dark-filter"></div>
                    <div className="filtre light-filter"></div>
                </div>
                <div className="intro">
                                       
                    <div>
                        <StaticImage src="../images/ico3.svg" alt="Compétences" objectFit="contain" layout="constrained" width={48} height={48} />
                        <ul>
                            {props.data.contentfulPortfolioItem.itemPrestations.map((prestation) => {
                                return(
                                    <li>{prestation}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                
                <section>
                
                    <article id="step1">
                        <div>
                            <h2><span>1.</span><br/>Présentation</h2>
                            <span className="categ">client</span>
                            <p>
                                {
                                     /*documentToReactComponents(props.data.contentfulPortfolioItem.presentation.json)*/
                                     renderRichText(project.presentation)
                                }
                            </p>
                            
                        </div>
                        <div className="image">
                         
                            <GatsbyImage className="portfolio-step1-image" image={project.step1.gatsbyImageData} alt={project.step1.title} objectFit="contain" />
                            <span className="figcaption">{project.step1.description}</span>
                        </div>
                        
                    </article>
                    <article id="step2">
                        <div id="skills">
                            <h2><span>2.</span><br/>Réalisation</h2>
                            <p>{project.description.description}</p>
                        </div>
                        <div className="image">
                            <GatsbyImage className="portfolio-step2-image" image={project.step2.gatsbyImageData} alt={project.step2.title} objectFit="contain" />
                            <span className="figcaption">{project.step2.description}</span>
                        </div>
                    </article>
                    <article id="showcase">
                        <div>
                            <h2><span>3.</span><br/>Résultat</h2>
                            {lienProjet}
                        </div>
                        <div className="image">
                         
                       
                        <GatsbyImage className="portfolio-showcase-image" image={project.showcase.gatsbyImageData} alt={project.showcase.title} objectFit="contain" />
                            
                        </div>
                    </article>

                   
                </section>
                <div className="prev-next">
                
                 { next && <Link className="next" to={`/portfolio/${next.slug}`}>Précédent</Link> }
                 { prev && <Link className="previous" to={`/portfolio/${prev.slug}`}>Suivant</Link> }
                </div>
                

            </StyledPortfolioTemplate>
             
        </Layout>
    );
};

export default portfolio;